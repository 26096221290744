import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
	createRef,
} from "react";
import Container from "react-bootstrap/Container";
import {
	ButtonToolbar,
	Col,
	InputGroup,
	Row,
	Spinner,
	ToggleButton,
	ToggleButtonGroup,
} from "react-bootstrap";
import Layout from "../../components/layout";
import Button from "react-bootstrap/Button";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
// import LocalBusinessSchema from "../../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";

import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import RecordForm from "../../components/record-form";
import MultiStepForm from "../../components/multi-step-form";
import HeroSectionHome from "../../components/hero-section-home";
import BrokerSectionHome from "../../components/broker-section-home";
import ReviewSection from "../../components/review-section";
import ReviewSectionHome from "../../components/review-section";
import RecentLeadsList from "../../components/use-fetch-leads";
import useFetchData from "../../components/use-fetch-data";
import LeadsChart from "../../components/leads-chart";
import StatusPieChart from "../../components/status-pie-chart";
import RecentLeadsTable from "../../components/recent-leads-table";
import AdminLayout from "../../components/admin-layout";
import Sidebar from "../../components/side-nav";
import TopBarAdmin from "../../components/top-bar-admin";
import TotalBrokers from "../../images/total-brokers.svg";
import AllLeads from "../../images/daily-leads.svg";
import withRoleBasedRedirect from "../../components/with-role-based-redirects";

const AdminPage = () => {
	const [state, handleSubmit] = useForm("xzboblvd");
	const tl = useRef();

	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);
	const apiKey = process.env.GATSBY_MY_SECRET_API_KEY;

	// First use of the hook
	const {
		data: dataLeads,
		loading: loadingLeads,
		error: errorLeads,
	} = useFetchData("/.netlify/functions/fetchAirtableLeads", apiKey);

	// Second use of the hook
	const {
		data: dataBrokers,
		loading: loadingBrokers,
		error: errorBrokers,
	} = useFetchData("/.netlify/functions/fetchAirtableBrokers", apiKey);

	const addDays = (date, days) => {
		const result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	};

	const [selectedOption, setSelectedOption] = useState("Daily leads");
	const [leadsCount, setLeadsCount] = useState(0);

	// Helper function to filter records based on the dateCreated
	const filterRecordsByDate = (startDate, endDate = new Date()) => {
		return dataLeads.filter((record) => {
			const dateCreated = new Date(record.dateCreated);
			return dateCreated >= startDate && dateCreated < endDate;
		});
	};

	// Function to update the leads count based on the selected option
	const updateLeadsData = (option) => {
		let startDate;
		const endDate = new Date(); // Assuming we want to filter up to the current date

		switch (option) {
			case "Daily leads":
				startDate = addDays(endDate, -1);
				break;
			case "Weekly leads":
				startDate = addDays(endDate, -7);
				break;
			case "Monthly leads":
				startDate = addDays(endDate, -30);
				break;
			case "Quarterly leads":
				startDate = addDays(endDate, -90);
				break;
			default:
				startDate = endDate;
		}

		const filteredRecords = filterRecordsByDate(startDate);
		setLeadsCount(filteredRecords.length);
	};

	// Effect hook to update data when the selected option changes
	useEffect(() => {
		updateLeadsData(selectedOption);
	}, [selectedOption, dataLeads]); // Add records to dependency array to update count when records change

	if (loadingBrokers || loadingLeads) {
		return (
			<AdminLayout>
				{" "}
				<div
					style={{ width: "100%", height: "100%" }}
					className="position-relative"
				>
					<div
						style={{ top: "50vh" }}
						className="position-absolute start-50 translate-middle"
					>
						<Spinner
							style={{ width: "6rem", height: "6rem" }}
							animation="border"
							role="status"
							variant="primary"
						>
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>

					<GatsbySeo title="Dashboard" language="en" noindex nofollow />
				</div>
			</AdminLayout>
		);
	}

	return (
		<>
			{/* <GatsbySeo
				title="Dentist in Exeter | Dental House Exeter"
				description="Highest quality dental care in a warm and welcoming setting, offering the newest in digital dentistry"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Dentist in Exeter | Dental House Exeter",
					description:
						"Highest quality dental care in a warm and welcoming setting, offering the newest in digital dentistry",
					//images: [
					//	{
					//		url: `${data.heroImg?.localFile.publicURL}`,
					//		width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
					//		height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
					//		alt: `${data.heroImg?.altText}`,
					//	},
					//],
				}}
			/> */}

			<div>
				<AdminLayout>
					<GatsbySeo title="Dashboard" language="en" noindex nofollow />
					<Row>
						<Col>
							<h1 className="mb-4">Dashboard</h1>
						</Col>
					</Row>
					<Row>
						<Col lg={6}>
							<Row className="g-4">
								<Col lg={6}>
									<div
										style={{ borderRadius: "12px" }}
										className="bg-white p-4"
									>
										<Row className="align-items-center gx-0">
											<Col lg={9}>
												<h2 className="fs-5 text-schema-grey">Total Brokers</h2>
												<p className="fs-2 mb-0 pb-0 Jakarta-Bold">
													{dataBrokers.length}
												</p>
											</Col>
											<Col lg={3}>
												<img
													className=" d-none d-lg-inline-block"
													src={TotalBrokers}
													alt="Brokers Icon"
													style={{
														width: "100%",
													}}
												/>
											</Col>
										</Row>
									</div>
								</Col>
								<Col lg={6}>
									<div
										style={{ borderRadius: "12px" }}
										className="bg-white p-4"
									>
										<Row className="align-items-center gx-0">
											<Col lg={9}>
												<DropdownButton
													title={selectedOption}
													style={{
														backgroundColor: "transparent",
														color: "#555", // Replace with your desired color
														borderColor: "transparent",
														boxShadow: "none",
													}}
													size="lg"
													id="dropdown-basic-button"
													onSelect={(eventKey) => setSelectedOption(eventKey)}
												>
													<Dropdown.Item eventKey="Daily leads">
														Daily leads
													</Dropdown.Item>
													<Dropdown.Item eventKey="Weekly leads">
														Weekly leads
													</Dropdown.Item>
													<Dropdown.Item eventKey="Monthly leads">
														Monthly leads
													</Dropdown.Item>
													<Dropdown.Item eventKey="Quarterly leads">
														Quarterly leads
													</Dropdown.Item>
												</DropdownButton>
												<p className="fs-2 mb-0 pb-0 Jakarta-Bold">
													{leadsCount.toLocaleString()}
												</p>
											</Col>
											<Col lg={3}>
												<img
													className=" d-none d-lg-inline-block"
													src={AllLeads}
													alt="Leads Icon"
													style={{
														width: "100%",
													}}
												/>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className=" mt-4 gx-4 justify-content-between">
						<Col lg={7}>
							<div style={{ borderRadius: "12px" }} className="bg-white p-4">
								<LeadsChart leads={dataLeads} />
							</div>
						</Col>
						<Col lg={5}>
							<div style={{ borderRadius: "12px" }} className="bg-white p-4">
								<StatusPieChart leads={dataLeads} />
							</div>
						</Col>
					</Row>
					<Row className="mt-4">
						<Col>
							<RecentLeadsTable leads={dataLeads} />
						</Col>
					</Row>
				</AdminLayout>
			</div>
		</>
	);
};

export default withRoleBasedRedirect(AdminPage, {
	requiredRole: "Super Admin",
});
